<div class="main">
    <div class="title" id="about-us">
        Om oss
    </div>
    <div class="text-holder">
        <div>
            <p>
                AKIRU STUDIOS utvecklar kundspecifika lösningar. Vi gör nyproduktion, vidareutveckling och
                integration samt
                omsorg
                av befintliga lösningar.
            </p>
            <p>Samarbeten med oss är oftast på prenumerationsbasis där
                prenumerationen innefattar utveckling och omsorg vilket ger våra klienter full trygghet i att deras
                digitala
                lösning ombesörjs och
                hålls ajour i en värld av ständig förändring.
            </p>

            <p>
                AKIRU är lösningen för små och medelstora bolag som förstår värdet i att inte ha en intern
                utvecklingsavdelning och bära kostnaden för den samt den utveckling och kunskap dess personal ständigt
                måste uppdatera för att vara ajour.
            </p>
            <p>
                Vi är som en intern byrå utanför ert bolag. Vi åtar oss ansvaret för driften och håller allt uppdaterat
                både tekniskt för klienten och vår kompetens.
            </p>
            <p>Med detta upplägget kan ni fokusera på det ni gör bäst och vi fokuserar på det vi gör bäst och brinner
                för.</p>
        </div>
    </div>
    <div class="akiru-filler"></div>
</div>
