<div class="main">
  <div class="title">
    Så här jobbar vi
  </div>
  <div class="letter-holder">
    <div class="letter-box">
      <img alt="A" src="/assets/a.png">
      <div>
        Vi finns <span class="anfang">ALLTID</span> tillhands. Vårt arbete går aldrig på
        helgledighet eller semester
        eftersom vi vet att ert behov av
        er sajt eller er digitala lösning är en vital del i er verksamhet.
      </div>
    </div>
    <div class="letter-box">
      <img alt="KI" src="/assets/ki.png">
      <div>
        Begreppet <span class="anfang">Ki</span> (Qi) visar vår tydliga arbetsglädje och märks i
        vårt engagemang vi har
        för det vi skapar tillsammans
        med våra klienter, leverantörer och uppdragsgivare.
      </div>
    </div>
    <div class="letter-box">
      <img alt="R" src="/assets/r.png">
      <div>
        Goda <span class="anfang">RELATIONER</span> är en grundförutsättning för att skapa något
        bra, som är fint och
        gör
        sitt jobb. Därför värnar vi
        om långsiktiga relationer med tydlig och god kommunikation.
      </div>
    </div>
    <div class="letter-box">
      <img alt="" height="199" ngSrc="/assets/u.png" width="199">
      <div>Vi levererar <span class="anfang">UTVECKLING</span>. Både hos oss och med er. Det är vår
        grundverksamhet och
        leverans. Utveckling är ett
        måste för oss, utvecklas vi inte så finns vi inte.
      </div>
    </div>
  </div>
  <div class="akiru-filler"></div>
</div>
