import {Component} from '@angular/core'
import {StateService} from '../services/state.service'

@Component({
  selector: 'aku-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(public stateService: StateService) {
  }
}
