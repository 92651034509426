import {Component, HostListener, Inject} from '@angular/core'
import {StateService} from '../../services/state.service'
import {WINDOW} from '../../application/window.provider'

@Component({
  selector: 'aku-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent {

  constructor(
    private stateService: StateService,
    @Inject(WINDOW) private injectedWindow: Window) {
  }

  @HostListener('document:scroll')
  public onScroll() {
    if (this.injectedWindow.scrollY > this.injectedWindow.innerHeight) {
      this.stateService.states$.next(true)
    } else {
      this.stateService.states$.next(false)
    }
  }

}
