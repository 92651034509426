<div class="main">
  <div class="arrow">
    <span [routerLink]="['/', 'about', { outlets: { content:
    'clients'}}]" class="material-symbols-outlined">
      arrow_back
    </span>
  </div>
  <div class="title">
    Sparbanken Syd
  </div>
  <div class="ingress">
    <div>
      ”Även små banker behöver tillhandahålla samma service och konstant funktion som större
      banker. Men har svårt att bära kostnaden för en fullskalig IT-avdelning.
    </div>
    <div>
      Att bygga en leverans som innefattade bygget av funktionerna och sidan samt underhåll
      genom prenumeration blev därför lyckat för Sparbanken Syd.”
    </div>
  </div>
  <div class="case-holder">
    <img alt="Lånelöfte" src="../../../../assets/clients/lanelofte.png">
  </div>
  <div class="akiru-filler"></div>
</div>
