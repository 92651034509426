<div class="holder">
    <div class="header about" *ngIf="(stateService.states$ | async) === true">
        <span class="material-symbols-outlined spacer">menu</span>
        <div class="akiru-filler"></div>
            <div [routerLink]="['/']" class="title">Akiru studios</div>
        <div class="akiru-filler"></div>
        <div [matMenuTriggerFor]="menu" aria-controls="menu" aria-haspopup="true" aria-label="Huvudmeny" class="menu">
        <span class="material-symbols-outlined">
          menu
        </span>
        </div>
        <mat-menu #menu="matMenu">
            <button
                    [routerLink]="['']"
                    mat-menu-item
                    role="menuitem">Hem
            </button>
            <button
                    [routerLink]="['id']"
                    mat-menu-item
                    role="menuitem">Digitalt id
            </button>
        </mat-menu>
    </div>
    <div class="header home" *ngIf="(stateService.states$ | async) === false">
            <div [matMenuTriggerFor]="menu" aria-controls="menu" aria-haspopup="true" aria-label="Huvudmeny" class="menu">
            <span class="material-symbols-outlined">
              menu
            </span>
            </div>
            <mat-menu #menu="matMenu">
                <button
                        [routerLink]="['']"
                        mat-menu-item
                        role="menuitem">Hem
                </button>
                <button
                        [routerLink]="['id']"
                        mat-menu-item
                        role="menuitem">Digitalt id
                </button>
            </mat-menu>
    </div>
</div>
