import {Component, Input} from '@angular/core'

@Component({
  selector: 'aku-next-arrow',
  templateUrl: './next-arrow.component.html',
  styleUrls: ['./next-arrow.component.scss']
})
export class NextArrowComponent {

  @Input() text = 'TExt'

  @Input() white = false
}
