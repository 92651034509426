<div (scroll)="onScroll()" class="page-top">
  <div class="akiru-filler"></div>
  <div class="main">
    <div class="akiru-filler"></div>
    <img alt="logo" src="assets/AKIRU-STUDIOS_logo-horiz-black_RGB.png">
    <div class="akiru-filler"></div>
  </div>
  <div class="akiru-filler"></div>
  <aku-next-arrow [white]="true" text="om oss"></aku-next-arrow>
</div>
<div class="page-rest">
  <aku-how-we-work></aku-how-we-work>
</div>
<div class="page-rest">
  <aku-about-us></aku-about-us>
</div>
<div class="page-rest">
  <aku-clients></aku-clients>
</div>
<div class="page-rest">
  <aku-we-are-akiru></aku-we-are-akiru>
</div>
