<div class="main">
    <div class="title" id="clients">
        Våra klienter
    </div>
    <div class="client-holder">
        <div class="client">
            <img alt="sparbanken"
                 src="./assets/clients/sparbanken2.png">
        </div>
        <div class="client">
            <img alt="Kulladal" src="./assets/clients/kulladal2.png">
        </div>
        <div class="client">
            <img alt="lillebror" src="./assets/clients/lillebrors2.png">
        </div>
    </div>
    <div class="akiru-filler"></div>
</div>
