import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {AppComponent} from './app.component'
import {HeaderComponent} from './header/header.component'
import {FooterComponent} from './footer/footer.component'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {MatButtonModule} from '@angular/material/button'
import {AppRoutingModule} from './app-routing.module'
import {StartComponent} from './0-pages/0-start/start.component'
import {AkiruCommonModule} from './common/common.module'
import {HowWeWorkComponent} from './0-pages/1-how-we-work/how-we-work.component'
import {AboutUsComponent} from './0-pages/2-about-us/about-us.component'
import {ClientsComponent} from './0-pages/3-clients/clients.component'
import {WeAreAkiruComponent} from './0-pages/4-we-are-akiru/we-are-akiru.component'
import {SparbankenComponent} from './0-pages/3-clients/0-sparbanken/sparbanken.component'
import {CommonModule, NgOptimizedImage} from '@angular/common'
import {IdidComponent} from './products/idid/idid.component'
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatTableModule} from "@angular/material/table"
import {MatGridListModule} from "@angular/material/grid-list"
import {ReactiveFormsModule} from "@angular/forms"
import {windowProvider} from './application/window.provider'

@NgModule({
  declarations: [
    AboutUsComponent,
    HowWeWorkComponent,
    AboutUsComponent,
    ClientsComponent,
    WeAreAkiruComponent,
    SparbankenComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    StartComponent,
    IdidComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    AkiruCommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatGridListModule,
    MatButtonModule,
    AkiruCommonModule,
    NgOptimizedImage,
    MatTooltipModule,
    ReactiveFormsModule
  ],
  providers:  [windowProvider],
  bootstrap: [AppComponent]
})
export class AppModule {
}
