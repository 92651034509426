import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {NextArrowComponent} from './next-arrow/next-arrow.component'


@NgModule({
  declarations: [
    NextArrowComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NextArrowComponent,
  ]
})
export class AkiruCommonModule {
}
