<div class="main">
    <div class="title">
        Bank-id digital legitimation
    </div>
    <div class="product-holder">
        <div class="product">
      <span class="material-symbols-outlined">
        phone_iphone
      </span>
            <div class="title">
                Mobile ready
            </div>
        </div>
        <div class="product">
    <span class="material-symbols-outlined">
        lock_clock
      </span>
            <div class="title">
                GDPR compliant
            </div>
        </div>
        <div class="product">
    <span class="material-symbols-outlined">
        verified_user
      </span>
            <div class="title">
                Bank-id verified
            </div>
        </div>
        <div class="product">
    <span class="material-symbols-outlined">
        import_contacts
      </span>
            <div class="title">
                Logged identification
            </div>
        </div>
        <div class="product">
    <span class="material-symbols-outlined">
        rebase
      </span>
            <div class="title">
                Integrated with your business system
            </div>
        </div>
    </div>
    <div class="text-holder">
        <div>
            <p>
                We have the app for verifying digital-ID from the Bank-ID app. It scans any QR code and verifies it with
                Bank-id
                and return the scanned ID's name, personnummer and age.
            </p>
            <p>
                Since the Bank-id digital-ID can be activated in front or you and contains a photo this will remove all
                false
                IDs. There will be no misreading in bad lighting or miscalculating.
            </p>
            <p>
                Digital records of all identifications are kept as per your needs and can be removed automatically for
                GDPR
                compliance.
            </p>
            <p>
                Customized features for your specific needs
                probably exist already, otherwise we are happy to add them.
            </p>
        </div>
    </div>
    <p></p>

    <div class="fee-holder">
        <table [dataSource]="dataSource" mat-table style="background: #f2f2f2">

            <ng-container matColumnDef="feeType">
                <th *matHeaderCellDef id="feeType" mat-header-cell>Fee</th>
                <td *matCellDef="let element" mat-cell> {{element.feeType}} </td>
            </ng-container>

            <ng-container matColumnDef="lowVol">
                <th *matHeaderCellDef id="lowVol" mat-header-cell>Low volume</th>
                <td *matCellDef="let element" mat-cell> {{element.lowVol}} </td>
            </ng-container>

            <ng-container matColumnDef="highVol">
                <th *matHeaderCellDef id="highVol" mat-header-cell>High volume</th>
                <td *matCellDef="let element" mat-cell> {{element.highVol}} </td>
            </ng-container>

            <ng-container matColumnDef="majVol">
                <th *matHeaderCellDef id="majVol" mat-header-cell>Major volume</th>
                <td *matCellDef="let element" mat-cell> {{element.majVol}} </td>
            </ng-container>

            <ng-container matColumnDef="flabVol">
                <th *matHeaderCellDef id="flabVol" mat-header-cell>Flabbergasting volume</th>
                <td *matCellDef="let element" mat-cell> {{element.flabVol}} </td>
            </ng-container>

            <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
            <tr *matRowDef="let element; columns: columnsToDisplay" mat-row></tr>

            <tr *matNoDataRow class="no-data">
                <td [attr.colspan]="columnsToDisplay.length" class="no-data">
                    Failure to find price list.
                </td>
            </tr>
        </table>
    </div>
    <p></p>
    <button mat-raised-button><a href="mailto:konichiwa@akiru.se?subject=Digital id scanning">Sign me up!</a></button>
    <p></p>
    <div class="akiru-filler"></div>
</div>
