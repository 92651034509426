import {Route, RouterModule} from '@angular/router'
import {NgModule} from '@angular/core'
import {StartComponent} from './0-pages/0-start/start.component'
import {IdidComponent} from './products/idid/idid.component'

export const routes: Route[] = [
  {
    path: '',
    component: StartComponent,
  },
  {
    path: 'id',
    component: IdidComponent,
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
