<div class="main">
  <div class="title" id="we-are-akiru">
    Vi är akriu!
  </div>
  <div class="person-holder">
    <div class="person">
      <img alt="Andreas Wikstrand Moresco" src="assets/people/moresco.png">
      <div class="name">Andreas Moresco</div>
      <div>Developer</div>
    </div>
    <div class="person">
      <img alt="Jörgen Lindberg" src="assets/people/jolg.png">
      <div class="name">Jörgen Lindberg</div>
      <div>Developer and customer contact</div>
    </div>
    <div class="person">
      <img alt="Daniel Bergdahl" src="assets/people/dabe.png">
      <div class="name">Daniel Bergdahl</div>
      <div>Developer and architect</div>
    </div>
  </div>
  <div class="person-holder">
    <div class="person">
      <img alt="Martin Harlenbäck" src="assets/people/martin.png">
      <div class="name">Martin Harlenbäck</div>
      <div>Developer</div>
    </div>
    <div class="person">
      <img alt="Darío Yuste Tirados" src="assets/people/dario.png">
      <div class="name">Darío Yuste Tirados</div>
      <div>Developer</div>
    </div>
    <div class="person">
      <img alt="Håkan Karlsson" src="assets/people/haka.png">
      <div class="name">Håkan Karlsson</div>
      <div>Senior Developer</div>
    </div>
  </div>
  <div class="akiru-filler"></div>
  <div class="address">
    <div>
      Akiru Studios,
      <a href="https://maps.app.goo.gl/nG5Abdtdu11Wq1mh8" target="_blank"><span
        [style.text-decoration]="'underline'">Carl Krooks Gata 25C, 252 18 Helsingborg</span></a>
    </div>
    <div>
      <a href="mailto:konichiwa@akiru.se">konichiwa&#64;akiru.se</a>
    </div>
    <div class="address">
      &copy; 2024 - Akiru Studios AB
    </div>
  </div>
</div>
