import {Component} from '@angular/core'

@Component({
  selector: 'aku-sparbanken',
  templateUrl: './sparbanken.component.html',
  styleUrls: ['./sparbanken.component.scss']
})
export class SparbankenComponent {

}
