import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public states$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
}
