import {Component} from '@angular/core'

@Component({
  selector: 'aku-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent {

}
